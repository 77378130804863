/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import {Inner} from '../components/styles'
import SocialLinks from '../components/SocialLinks'
import iconMobile from '../images/icons/icon-mobile-white.svg'
import iconLocation from '../images/icons/icon-location-white.svg'
import iconMail from '../images/icons/icon-mail-white.svg'
import CTA from '../components/shared/CTA'
import {isOnMobile} from '../utils'
import {pixelContactUs} from '../services/FacebookPixel'

import showroomImageImport from '../images/showroom_img.jpg'

const StyledContent = styled.div`
  margin-bottom: ${props => props.theme.vPadding};
`

const FormContainer = styled.div`
  background: ${props => props.theme.grey2};
  color: ${props => props.theme.white};
  margin: 0;
  padding: ${props => props.theme.vPadding} 0;
  text-align: center;
`
const ContactInformationContainer = styled.div`
  background: ${props => props.theme.grey2};
  color: ${props => props.theme.white};
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 80px;
  //min-height: 100px;
  //padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  svg {
    fill: white;
  }
  .grid {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 30px;
    //grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: ${props => props.theme.maxWidth};
    padding: 20px;
    .icon-link {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      align-items: center;
      transition: all 200ms ease-in-out;
      color: ${props => props.theme.white};
      &:hover {
        color: ${props => props.theme.red};
      }
    }
  }
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      .icon-link {
        grid-template-columns: 30px 1fr;
        img {
          justify-self: center;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`

const FormWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  div {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    margin: 20px;
  }
  .input-row {
    display: grid;
    grid-template-columns: 180px 1fr;
    border: 2px solid ${props => props.theme.grey3};
    border-radius: 5px;
    align-content: center;
    justify-items: left;
    label {
      color: ${props => props.theme.grey1};
      padding: 10px;
      background: ${props => props.theme.grey3};
      display: block;
      width: 100%;
      text-align: left;
    }
  }
  div.form-footer {
    justify-items: center;
  }
  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }
  input {
    letter-spacing: 0.5px;
    padding: 10px;
    background: ${props => props.theme.grey2};
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    border: none;
    color: ${props => props.theme.white};
  }
  textarea {
    letter-spacing: 0.5px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid ${props => props.theme.grey3};
    background: ${props => props.theme.grey2};
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    margin: 10px auto;
    color: ${props => props.theme.white};
  }
  input:focus,
  textarea:focus {
    background: ${props => props.theme.grey1};
  }
  input::placeholder,
  textarea::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
  button {
    background: ${props => props.theme.dark};
    color: ${props => props.theme.white};
    padding: 15px 25px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    border: none;
    outline: none;
  }
  button:hover,
  button:focus {
    cursor: pointer;
    background: ${props => props.theme.red};
  }
  .textarea-row {
    border: 2px solid ${props => props.theme.grey3};
    border-radius: 5px;
    label {
      color: ${props => props.theme.grey1};
      padding: 10px;
      background: ${props => props.theme.grey3};
      display: block;
      width: 100%;
      text-align: left;
    }
  }
  textarea {
    border: none;
    margin: 0;
  }
  @media (max-width: 500px) {
    .input-row {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`

const AddressBlocksContainerOuter = styled.div`
  //background: ${props => props.theme.grey2};
  // color: ${props => props.theme.white};
  //background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100px;
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
`
const AddressBlocksContainerInner = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  padding: 20px;
  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const AddressBlock = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 5px 0 10px;
  }
`

const AddressBlockText = styled.div`
  flex: 0.4;
  padding: 15px 20px;
  background-color: #cad4e0;
`

const AddressBlockMap = styled.div`
  flex: 0.6;
  iframe {
    height: 100%;
    max-height: 100%;
    min-height: 350px;
    @media (max-width: 780px) {
      min-height: 250px;
    }
  }
`

const ShowroomImage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  //padding-top: 56.25%;
  padding-top: 40%;
  .responsive-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

const ContactPage = ({location}) => {

  // const [test, setTest] = useState('')
  //
  // const isBrowser = typeof window !== "undefined";
  //
  // useEffect(() => {
  //   if (isBrowser) {
  //     const params = new URLSearchParams(window.location.search);
  //     setTest(params.get('test'))
  //   }
  // }, [isBrowser]);

  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 32}) {
        title
        content
        acf {
          phone
          email
          location
          location_link
          location_embed
          cta_title
          cta_description
          cta_button_text
          cta_link
          info_bg_image {
            source_url
          }
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 32) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} flush>

      <SEO title="About Us" location={location} seo={data.seo.pageBy.seo} />

      <PageHeader location={location}>{'Contact Us'}</PageHeader>

      <Inner>
        <StyledContent
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.content}}
        />
      </Inner>

      <ContactInformationContainer>
        <div className="grid">
          <div>
            <a
              href={`tel:${data.wp.acf.phone}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={iconMobile} alt="" />
              {data.wp.acf.phone}
            </a>
          </div>
          <div>
            <a
              href={`mailto:${data.wp.acf.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={iconMail} alt="" />
              {data.wp.acf.email}
            </a>
          </div>
          {/*<div>*/}
          {/*  <a*/}
          {/*    href={data.wp.acf.location_link}*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*    className="icon-link"*/}
          {/*  >*/}
          {/*    <img src={iconLocation} alt="" />*/}
          {/*    {data.wp.acf.location}*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div>
            <SocialLinks svgFillColour={'white'} />
          </div>
        </div>
      </ContactInformationContainer>

      <AddressBlocksContainerOuter>
        <AddressBlocksContainerInner>
          <AddressBlock>
            <AddressBlockMap>
              <iframe
                  src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3590.210027426761!2d28.254563!3d-25.862564!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95660f8ddb2ae1%3A0x60feea1da68a755c!2sThe%20Mattress%20Warehouse%20Pretoria!5e0!3m2!1sen!2sza!4v1676383018544!5m2!1sen!2sza"}
                  width="100%"
                  height="350"
                  frameBorder="0"
                  style={{border: 0}}
                  allowFullScreen
              />
            </AddressBlockMap>
            <AddressBlockText>
              <div><h3>The Mattress Warehouse - Pretoria</h3></div>
              <div><p>The Mattress Warehouse Pretoria, 47 Regency Street, Route 21 Corporate Park, Irene Ext 30, Centurion, 0157, South Africa</p></div>
              <div><p>012 345 3328</p></div>
              <div>
                <p>
                  Monday - Friday | 07:30 - 17:30<br />
                  Saturday | Closed<br />
                  Sunday | Closed
                </p>
              </div>
            </AddressBlockText>
          </AddressBlock>
          <AddressBlock>
            <AddressBlockMap>
              <iframe
                  src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.480586666655!2d18.66982963103207!3d-33.903028666355404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc513da6086411%3A0x5df42c88484cbe07!2sThe%20Mattress%20Warehouse%20Bellville!5e0!3m2!1sen!2sza!4v1676457760797!5m2!1sen!2sza"}
                  width="100%"
                  height="350"
                  frameBorder="0"
                  style={{border: 0}}
                  allowFullScreen
              />
            </AddressBlockMap>
            <AddressBlockText>
              <div><h3>The Mattress Warehouse - Bellville</h3></div>
              <div><p>Corner Virgo Cres & Dairy St, Stikland Industrial, Bellville, Cape Town, South Africa</p></div>
              <div><p>021 224 0073</p></div>
              <div>
                <p>
                  Monday - Friday | 07:30 - 17:30<br />
                  Saturday | Closed<br />
                  Sunday | Closed
                </p>
              </div>
            </AddressBlockText>
          </AddressBlock>
        </AddressBlocksContainerInner>
      </AddressBlocksContainerOuter>

      <ShowroomImage>
        <img src={showroomImageImport} className={"responsive-image"} alt={"Showroom"} />
      </ShowroomImage>

      {/*<iframe*/}
      {/*  src={data.wp.acf.location_embed}*/}
      {/*  width="100%"*/}
      {/*  height="600"*/}
      {/*  frameBorder="0"*/}
      {/*  style={{border: 0}}*/}
      {/*  allowFullScreen*/}
      {/*/>*/}

      <FormContainer>
        <Inner>
          <div className="form">
            <FormWrapper>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/contact-us/contact-request-received"
                onSubmit={pixelContactUs}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="input-row">
                  <label htmlFor="fullname">Name</label>
                  <input
                    type="text"
                    id="fullname"
                    name="Name"
                    autoFocus={isOnMobile() ? false : true}
                    required
                    placeholder="Please enter your full name"
                  />
                </div>
                <div className="input-row">
                  <label htmlFor="phone">Contact Number</label>
                  <input
                    type="tel"
                    id="phone"
                    name="Contact Number"
                    placeholder="(000) 000-0000"
                  />
                </div>
                <div className="input-row">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="Email"
                    required
                    placeholder="name@domain.com"
                  />
                </div>
                <div className="textarea-row">
                  <label htmlFor="comments">How can we help?</label>
                  <textarea
                    id="comments"
                    name="Comments"
                    required
                    placeholder="Tell us a bit more about your enquiry..."
                    rows={6}
                  />
                </div>
                <div className="form-footer">
                  <button type="submit">SEND MESSAGE</button>
                </div>
              </form>
            </FormWrapper>
          </div>
        </Inner>
      </FormContainer>

      <CTA data={data.wp.acf} />

    </Layout>
  )
}

export default ContactPage
