import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {Inner} from '../../styles'

const CTAContainer = styled.div`
  text-align: center;
  padding: 30px 0;
  .cta-button {
    font-size: 18px;
    background: ${props => props.theme.grey1};
    color: ${props => props.theme.white};
    padding: 15px 35px;
    border-radius: 50px;
    margin-top: 20px;
    text-transform: uppercase;
    display: inline-block;
    transition: all 200ms ease-in-out;
    &:hover {
      background: ${props => props.theme.red};
    }
  }
`
const CTA = ({data}) => {
  if (!data) {
    return '...'
  }
  return (
    <CTAContainer>
      <Inner>
        <h2 className="red">{data.cta_title}</h2>
        <p>{data.cta_description}</p>
        <Link to={data.cta_link} className="cta-button">
          {data.cta_button_text}
        </Link>
      </Inner>
    </CTAContainer>
  )
}

export default CTA
