import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import styled from 'styled-components'
import FacebookSvg from '../../images/svg/facebook.jsx'
import TwitterSvg from '../../images/svg/twitter.jsx'
import InstagramSvg from "../../images/svg/instagram";

const IconRow = styled.div`
  @media (max-width: 480px) {
    width: 100%;
  }
`

const Icon = styled.a`
  padding-top: 13px;
  margin: 5px;
  text-align: center;
  // opacity: 0.7;
  transition: all 200ms ease-in-out;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background: ${props => props.theme.grey1};
  &:hover {
    opacity: 1;
    &.facebook {
      background: #4267b2;
    }
    &.twitter {
      background: #1da1f2;
    }
    &.instagram {
      background: #d62976;
    }
    &.email {
      background: ${props => props.theme.red};
    }
  }
`

const SOCIAL_LINKS = graphql`
  query SocialLinksDetails {
    wordpressPage(wordpress_id: {eq: 32}) {
      acf {
        social_links {
          title
          link
        }
      }
    }
  }
`

const SocialLinks = ({svgFillColour = '#cad4e0'}) => {
  return (
    <StaticQuery
      query={SOCIAL_LINKS}
      render={({wordpressPage}) => (
        <IconRow>
          {wordpressPage.acf.social_links.map(item => {
            return (
              <Icon
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={item.title}
                key={item.title}
              >
                {/*{item.title === 'twitter' ? (*/}
                {/*  <TwitterSvg fill={svgFillColour} />*/}
                {/*) : (*/}
                {/*  <FacebookSvg fill={svgFillColour} />*/}
                {/*)}*/}
                {item.title === 'twitter' && <TwitterSvg fill={svgFillColour} />}
                {item.title === 'facebook' && <FacebookSvg fill={svgFillColour} />}
                {item.title === 'instagram' && <InstagramSvg fill={svgFillColour} />}
              </Icon>
            )
          })}
        </IconRow>
      )}
    />
  )
}

export default SocialLinks
